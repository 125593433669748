<template>
  <div :class="$style.container">
    <loader v-if="loading" center />
    <div v-else-if="error" class="absolute-center" :class="$style.error">
      <div>{{ $t("Photowall.errorImages") }}</div>
      <ws-button color="primary" @click="$emit('refresh')">{{ $t("common.update") }}</ws-button>
    </div>
    <slot v-else />
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import Loader from "@/components/common/elements/Loader";

export default {
  name: "LayoutPhotoWall",
  components: { Loader, WsButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // & > div:first-child {
  //   flex: 0 0 auto;
  //   padding: 10px 16px;
  // }
}

.error {
  width: 100%;
  text-align: center;

  & > *:first-child {
    margin-bottom: 10px;
  }
}
</style>