<template>
  <div :class="$style.container">
    <div :class="$style.preview" :style="`background-image: url(${photo.srcPreview})`" />
    <div :class="$style.overflow" @click="$emit('preview')" />
    <item-checkbox
      v-if="availableDownload"
      :id="photo.id"
      :class="[$style.control, { [$style.control_checked]: selectedPhotoIds.includes(photo.id) }]"
      :items="selectedPhotoIds"
      @input="$emit('select', $event)"
    >
      <ws-button
        v-if="availableDownload"
        :data-name="`image_${photo.id}`"
        :data-url="photo.src"
        :class="$style.downloadBtn"
        color="default"
        icon
        sm
        @click="$emit('download', $event)"
      >
        <ws-icon sm color="primary">cloud-download-alt</ws-icon>
      </ws-button>
    </item-checkbox>
  </div>
</template>

<script>
import ItemCheckbox from "@/components/common/elements/ItemCheckbox";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import PhotoOnWall from "@/models/PhotoOnWall";

/**
 * Отображение превьющек фотографий
 *
 * @vue-prop {object} photo - обьект по которому строиться компонента
 * @vue-prop {Array} selectedPhotoIds - выбранные айдишники для последующей скачки
 * @vue-prop {boolean} availableDownload - включен ли модуль загрузки изображений
 * @vue-event {Array} select - новый массив с выбранными фотографиями
 * @vue-event {event} download - скачать изображение на которое кликнули
 */
export default {
  name: "PhotoWallItem",
  components: {
    ItemCheckbox,
    WsButton,
    WsIcon,
  },
  props: {
    photo: {
      type: PhotoOnWall,
      default: () => new PhotoOnWall(),
    },
    selectedPhotoIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.preview {
  border: 1px solid #ececec;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overflow {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.downloadBtn {
  background-color: #f4f4f4;
}

@media screen and (min-width: 1024px) {
  .container {
    &:hover .overflow {
      opacity: 0.4;
    }

    .downloadBtn,
    .control {
      opacity: 0;
      transition: opacity 0.3s ease-in;

      &_checked {
        opacity: 1;
      }
    }

    &:hover .downloadBtn,
    &:hover .control {
      opacity: 1;
    }
  }
}
</style>
