<template>
  <div v-if="!photos.length" :class="$style.empty" class="absolute-center">
    {{ $t("Photowall.noImages") }}
  </div>
  <div v-else :class="$style.container">
    <photo-wall-item
      v-for="photo of photos"
      :key="photo.id"
      :photo="photo"
      :available-download="availableDownload"
      :selected-photo-ids="selectedIds"
      @select="$emit('set-ids', $event)"
      @preview="$emit('current', photo)"
      @download="$emit('download', $event)"
    />
    <infinite-loading @infinite="$emit('load-more', $event)">
      <div slot="spinner"><loader :class="$style.loader" center /></div>
      <div slot="no-results"></div>
      <div slot="no-more"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Loader from "@/components/common/elements/Loader";
import PhotoWallItem from "@/components/room/photoWall/PhotoWallItem";

/**
 * Список фотографий его отображение
 *
 * @vue-prop {Array} photos - список фотографий
 * @vue-prop {Array} selectedIds - выбранные ids
 * @vue-prop {boolean} availableDownload - разрешено ли скачивание фото
 * @vue-event {Array} set-ids - новый масив с выбранными айди
 * @vue-event {object} current - выбрать текущее фото
 * @vue-event {event} download - скачать фото, передает евент клика
 * @vue-event {object} load-more - дозагрузка фото
 */
export default {
  name: "PhotoWallList",
  components: { InfiniteLoading, PhotoWallItem, Loader },
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  flex: 1 1 auto;
  padding: 0 10px;
  overflow: auto;
  max-height: 100%;
  margin: 10px 0;

  @include mq-tablet {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.loader {
  top: initial;
  bottom: 10px;
}

.empty {
  width: 80%;
  text-align: center;
}
</style>
