<template>
  <div :class="$style.container" @click="$emit('close')">
    <div :class="$style.image">
      <a v-show="loading" :href="photo.src" target="_blank">
        <img :src="photo.src" :alt="photo.id" @load="loading = true" />
      </a>
      <loader v-show="!loading" center />
    </div>
    <ws-button
      fab
      color="primary"
      :class="$style.btnLeft"
      @click.stop="$emit('change-photo', -1)"
    >
      <ws-icon>chevron-left</ws-icon>
    </ws-button>
    <ws-button
      fab
      color="primary"
      :class="$style.btnRight"
      @click.stop="$emit('change-photo', 1)"
    >
      <ws-icon>chevron-right</ws-icon>
    </ws-button>
  </div>
</template>

<script>
import PhotoOnWall from "@/models/PhotoOnWall";
import Loader from "@/components/common/elements/Loader";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

/**
 * Компонента просмотра фотографии
 *
 * @vue-prop {object} photo - фотография по ней строиться компонента
 * @vue-data {boolean} loading - статус загрузки текущего фото
 * @vue-event close - закрытие просмоторщика
 * @vue-event {number} change-photo - сменить фото на след. или пред.
 */
export default {
  name: "PhotoWallViewer",
  components: {
    Loader,
    WsButton,
    WsIcon,
  },
  props: {
    photo: {
      type: PhotoOnWall,
      default: () => new PhotoOnWall(),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    photo() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(#000, 0.8);
}

.image {
  margin: auto;
  text-align: center;

  img {
    display: block;
    margin: auto;
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 160px);
    object-fit: contain;
  }
}

.btnRight,
.btnLeft {
  position: absolute;
  top: 50%;
  left: 5px;
  z-index: 100; // Чтобы кнопки были всегда кликабельные
  min-width: 30px;
  height: 30px;
  transform: translateY(-50%);
}

.btnRight {
  left: initial;
  right: 5px;
}
</style>
