<template>
  <layout-photo-wall :loading="loading" :error="error" @refresh="init">
    <download-header
      v-if="isAvailableDownload && photos.length"
      :is-disable-selected-button="selectedPhotoIds.length < 1"
      :all-items-url="allPhotosUrl"
      :selected-items-url="selectedPhotosUrl"
      @clear-item="selectedPhotoIds = []"
    />
    <photo-wall-list
      :photos="photos"
      :selected-ids="selectedPhotoIds"
      :available-download="isAvailableDownload"
      @set-ids="selectedPhotoIds = $event"
      @current="currentPhoto = $event"
      @download="downloadImg"
      @load-more="loadMore"
    />
    <photo-wall-viewer
      v-if="currentPhoto"
      :photo="currentPhoto"
      @close="currentPhoto = null"
      @change-photo="changeCurrentPhoto"
    />
  </layout-photo-wall>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import config from "@/settings/config";
import { GET_PHOTO_WALL_IMAGES } from "@/store/modules/photowall/action-types";
import { RESET_PHOTO_WALL } from "@/store/modules/photowall/mutation-types";
import { photowall, room } from "@/store/modules/store.namespaces";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import DownloadHeader from "@/components/common/elements/DownloadHeader";
import LayoutPhotoWall from "@/components/layouts/LayoutPhotoWall";
import PhotoWallViewer from "@/components/room/photoWall/PhotoWallViewer";
import PhotoWallList from "@/components/room/photoWall/PhotoWallList";

/**
 * Вьюха фотостены отображает загруженные в админке фотографии
 *
 * @vue-data {boolean} loading - загрузка данных
 * @vue-data {boolean} error - ошибка при инициализации
 * @vue-data {null|object} currentPhoto - ошибка при инициализации
 * @vue-data {Array} selectedPhotoIds - выбранные фотографии для дальнейшего скачивания
 * @vue-data {boolean} photoIsLoading - статус загрузки одной фотографии
 * @vue-computed {Array} photos - масив фотографии загруженные
 * @vue-computed {string} roomNumber - номер текущей комнаты
 * @vue-computed {string} availableRoomModules - разрешенные модули для данной комнаты
 * @vue-computed {string} baseDownloadUrl - основной юрл для скачивания фото
 * @vue-computed {string} allPhotosUrl - урл по которому мы можем скачать все фотографии
 * @vue-computed {string} selectedPhotosUrl - урл по которому можно скачать выбранные фотографии
 * @vue-computed {boolean} isAvailableDownload - разрешенн ли модуль скачивания
 */
export default {
  name: "PhotoWall",
  components: {
    PhotoWallList,
    PhotoWallViewer,
    LayoutPhotoWall,
    DownloadHeader,
  },
  data() {
    return {
      loading: false,
      error: false,
      currentPhoto: null,
      selectedPhotoIds: [],
      photoIsLoading: false,
    };
  },
  computed: {
    ...mapState(photowall, ["photos"]),
    ...mapState(room, ["roomNumber"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    baseDownloadUrl() {
      return `${config.serverUrl}/web/api/downloadPhotowall/${this.roomNumber}`;
    },
    allPhotosUrl() {
      return `${this.baseDownloadUrl}/all`;
    },
    selectedPhotosUrl() {
      return `${this.baseDownloadUrl}/${this.selectedPhotoIds.join(",")}`;
    },
    isAvailableDownload() {
      return !!this.availableRoomModules.downloadPhotoWallModule;
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.resetPhotoWall();
  },
  methods: {
    ...mapActions(photowall, {
      getPhotoWallImages: GET_PHOTO_WALL_IMAGES,
    }),
    ...mapMutations(photowall, {
      resetPhotoWall: RESET_PHOTO_WALL,
    }),
    /**
     * Инициализация модуля фотографии, запрос за фотками
     *
     * @returns {Promise<void>}
     */
    async init() {
      try {
        this.resetPhotoWall();
        this.error = false;
        this.loading = true;
        await this.getPhotoWallImages();
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    /**
     * Догрузить фотографии дальше
     *
     * @param {object} $state - Обьект из плагина vue-infinite-loading позволяет указать
     * плагину что лези лоадинг завершен
     * @returns {Promise<void>}
     */
    async loadMore($state) {
      const res = await this.getPhotoWallImages();
      if (res && res === "complete") {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    /**
     * Изменяет текущее фото на предыдущее или следующее
     *
     * @param {number} direction - в зависимости он числа меняет текущее фото для просмоторщика фото
     * Пример: 1 - вперед или -1 - назад
     * @returns {void}
     */
    changeCurrentPhoto(direction) {
      const currentIndex = this.photos.findIndex(photo => photo.id === this.currentPhoto.id);
      const currentPhoto = this.photos[currentIndex + direction];
      if (!currentPhoto) return;
      this.currentPhoto = currentPhoto;
    },
    /**
     * Скачивание изображения на ПК по url картинки
     *
     * @param {event} event - событие клика по кнопке на картинке из события беруться все данные
     * @returns {Promise<void>}
     */
    async downloadImg(event) {
      if (!event?.currentTarget?.dataset) return;
      const { url } = event.currentTarget.dataset;
      const urlParts = url.split("/");
      const name = urlParts[urlParts.length - 1];
      try {
        if (this.$isCordova) {
          window.open(url, "_system");
        } else {
          this.photoIsLoading = true;
          const response = await fetch(url);
          const blob = await response.blob();
          const convertedUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = convertedUrl;
          a.download = name;
          a.click();
          a.remove();
        }
      } catch (e) {
        this.$dialog
          .alert(
            {
              title: this.$t("Photowall.loadImgError"),
            },
            {
              okText: this.$t("common.okText"),
              reverse: true,
              customClass: "dg-alert",
            },
          )
          .then(() => {
            this.$dialog.destroy();
          });
      } finally {
        this.photoIsLoading = false;
      }
    },
  },
};
</script>
